import axios from 'axios'

export default {
    platformCategory () {
        // 平台品类
        return axios.fetch('productServer', '/trade-platform/trade-platform-list')
    },
    parentCategory (query) {
        // todo:父级类 and 列表
        return axios.fetch('sellServer', '/sell-category/sell-category-tree', query)
    },
    createCategory (query) {
        // 添加品类
        return axios.fetch('sellServer', '/sell-category/sell-category-create', query)
    },
    updateCategory (query) {
        // 修改品类
        return axios.fetch('sellServer', '/sell-category/sell-category-update', query)
    },
    disabledCategory (query) {
        // 停用
        return axios.fetch('sellServer', '/sell-category/sell-category-disable', query)
    },
    enabledCategory (query) {
        // 停用
        return axios.fetch('sellServer', '/sell-category/sell-category-enabled', query)
    },
    getChannelList (query) {
        // 获取渠道列表
        return axios.fetch('sellServer', '/trade-channel/trade-channel-page', query)
    },
    getChannels (query) {
        // 获取渠道列表非分页
        return axios.fetch('sellServer', '/trade-channel/trade-channel-list', query)
    },
    createChannel (query) {
        // 添加渠道
        return axios.fetch('sellServer', '/trade-channel/trade-channel-create', query)
    },
    createCompetingChannel (query) {
        // 添加渠道
        return axios.fetch('sellServer', '/trade-channel/create-by-indicator', query)
    },
    updateChannel (query) {
        // 编辑渠道
        return axios.fetch('sellServer', '/trade-channel/trade-channel-update', query)
    },
    getProductBrands (query) {
        // 获取品牌
        return axios.fetch('productServer', '/product-brand/product-brand-list', query)
    },
    disabledChannel (query) {
        // 停用渠道
        return axios.fetch('sellServer', '/trade-channel/trade-channel-disable', query)
    },
    enabledChannel (query) {
        // 启用渠道
        return axios.fetch('sellServer', '/trade-channel/trade-channel-enable', query)
    },
    deleteRelation () {
        // 删除关联
    },
    saveRelation (query) {
        // 保存关联
        return axios.fetch('sellServer', '/sell-channel-relation/channel-relation-save', query, {
            'Content-Type': 'application/json'
        })
    },
    getRelations (query) {
        // 获取关联
        return axios.fetch('sellServer', '/sell-channel-relation/sell-channel-relation-list', query)
    },
    removeRelation (query) {
        // 删除关联
        return axios.fetch('sellServer', '/sell-channel-relation/channel-relation-delete', query)
    },
    getSpuList (query) {
        // 获取商品列表
        return axios.fetch('sellServer', '/sell-spu/sell-spu-page', query)
    },
    createSpu (query) {
        // 保存spu
        return axios.fetch('sellServer', '/sell-spu/sell-spu-create', query, {
            'Content-Type': 'application/json'
        })
    },
    updateSpu (query) {
        // 修改spu
        return axios.fetch('sellServer', '/sell-spu/sell-spu-update', query, {
            'Content-Type': 'application/json'
        })
    },
    getSkuList (query) {
        // 获取sku
        return axios.fetch('productServer', '/product-sku/product-sku-list', query, {
            'Content-Type': 'application/json'
        })
    },
    getSkulistPage (query) {
        return axios.fetch('productServer', '/product-sku/product-sku-page', query)
    },
    getGoodsSpu (query) {
        return axios.fetch('productServer', '/product-spu/product-spu-list', query)
    },
    saveRelationSpu (query) {
        // 保存关联
        return axios.fetch('sellServer', '/sell-spu-relation/sell-spu-relation-save', query, {
            'Content-Type': 'application/json'
        })
    },
    removeRelationSpu (query) {
        // 删除关联spu
        return axios.fetch('sellServer', '/sell-spu-relation/sell-spu-relation-delete', query)
    },
    spuRelation (query) {
        // 获取spu
        return axios.fetch('sellServer', '/sell-spu-relation/sell-spu-relation-list', query)
    },
    skuRelation (query) {
        // 获取sku
        return axios.fetch('sellServer', '/sell-sku/sell-sku-list', query)
    },
    removeRelationSku (query) {
        // 删除关联sku
        return axios.fetch('sellServer', '/sell-sku/sell-sku-delete', query)
    },
    getGoodsSpuPage (query) {
        return axios.fetch('productServer', '/product-spu/product-spu-page', query)
    }
}
