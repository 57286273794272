import axios from '@/utils/https'
import request from '@/utils/request'
import { query } from '../modules/customer/category/components'
const fetch = (
    path = '',
    params = {},
    restConfig = {
        method: 'post',
        'Content-Type': 'application/json'
    }
) =>
    request('oaServer', path, {
        params,
        ...restConfig
    })

export default {
    // ------------组织相关--------------
    getOwnDepts () {
        // 获取当前员工 所在组织及其子组织
        return axios.fetch('oaServer', '/oa/employee-group-children')
    },
    getAllDepts (query) {
        // 获取当前租户 所有组织
        return axios.fetch('oaServer', '/oa/oa-group-list', query)
    },

    addDept (query) {
        return axios.fetch('oaServer', '/oa/oa-group-add', query, 'json')
    },
    // 编辑
    editDept (query) {
        return fetch('/oa/oa-group-edit', query, {
            openLoading: true
        })
    },
    delDept (query) {
        return axios.fetch('oaServer', '/oa/oa-group-delete', query, 'json')
    },
    // 根据组织id查询负责人，如果负责人没有则查询上级负责人以此类推
    getAdmin (query) {
        return axios.fetch('oaServer', '/oa/group-admin', query, 'json')
    },
    // ------------岗位相关--------------
    getJobList (query) {
        // 当前租户jobList
        return axios.fetch('oaServer', '/oa/oa-job-list', query)
    },
    addJob (query) {
        return axios.fetch('oaServer', '/oa/oa-job-add', query, 'json')
    },
    editJob (query) {
        return axios.fetch('oaServer', '/oa/oa-job-edit', query, 'json')
    },
    delJob (query) {
        return axios.fetch('oaServer', '/oa/oa-job-delete', query, 'json')
    },
    // ------------员工相关--------------
    getEmlpoyeesList (query) {
        // 获取全部员工数据
        return fetch('/oa/employee-info-list', query, {
            dataType: 'form'
        })
    },
    // 获取员工分页数据
    getEmlpoyeesPage (query) {
        // 获取全部员工数据
        return axios.fetch('oaServer', '/oa/employee-info-page', query)
    },
    getEmployeeInfo (query) {
        // 获取单个员工信息
        return axios.fetch('oaServer', '/oa/employee-info-ehr', query)
    },
    editEmploeeFile (query) {
        // 员工档案修改
        return axios.fetch('oaServer', '/oa/employee-info-edit', query, 'json')
    },
    addEmploeeFile (query) {
        // 员工档案修改
        return axios.fetch('oaServer', '/oa/employee-info-add', query, 'json')
    },
    leaveOffice (query) {
        // 员工离职
        return axios.fetch('oaServer', '/oa/leaveOffice', query, 'json')
    },
    employeeSummary (query) {
        // 员工概述
        return axios.fetch('oaServer', '/oa/employee-summary', query, 'json')
    },

    // 添加岗位分类
    addOajobcategory (query) {
        return axios.fetch('oaServer', '/oajobcategory/save', query, 'json')
    },
    // 岗位分类列表
    jobcategoryList (query) {
        return axios.fetch('oaServer', '/oajobcategory/list', query, 'json')
    },
    /* ----------------------------------- 工作台 ---------------------------------- */
    /* ----- https://i.gubeios.cn/wiki/pages/viewpage.action?pageId=4784620 ----- */
    getEmployeeWidget () {
        // 1.获取员工自定义工作台配置
        return fetch('/desktop/employee-widget')
    },
    updateEmployeeWidget (params) {
    // 2.更新员工自定义工作台配置
        return fetch('/desktop/update-employee-widget', params)
    },
    getAuthorizedWidget (params) {
        // 3.获取用户权限可用的卡片列表
        return fetch('/desktop/get-authorized-widget', params)
    }

    // 公司列表
}
