<template>
    <el-dialog
        class="add-dialog"
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        width="500px"
        :before-close="handleCancel"
        :destroy-on-close="true"
        :close-on-click-modal="false"
    >
        <el-form ref="form" :model="form" :rules="rules">
            <el-form-item label="平台" label-width="80px" prop="platformId">
                <el-select
                    v-model="form.platformId"
                    placeholder="平台"
                    :disabled="!isAdd"
                    :filterable="true"
                    :popper-append-to-body="false"
                    @change="handlePlatformidChange"
                >
                    <el-option
                        v-for="item in platformCategory"
                        :key="item.id"
                        :label="item.platformName"
                        :value="item.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item
                v-if="form.platformId"
                label="品类"
                label-width="80px"
                prop="categoryName"
            >
                <el-input
                    v-model.trim="form.categoryName"
                    style="width: 211px"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item
                v-if="form.platformId"
                label="父品类"
                label-width="80px"
                prop="parentId"
            >
                <el-cascader
                    ref="cascaderHandle"
                    v-model="form.parentId"
                    :options="parentCategory"
                    :props="{
                        checkStrictly: true,
                        value: 'id',
                        label: 'categoryName',
                        emitPath: false,
                    }"
                    clearable
                    :filterable="true"
                    @change="handleClose"
                ></el-cascader>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button
                v-if="isAdd"
                type="primary"
                @click="handleConfirm('continue')"
                >保存并继续添加</el-button
            >
            <el-button type="primary" @click="handleConfirm">保存</el-button>
            <el-button @click="handleCancel">取消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import _ from "lodash"
import { mapState } from "vuex"
import customerSvc from "services/customer"

export default {
    name: "AddDialog",
    // props: {
    //     platformId: {
    //         type: String,
    //         default: ''
    //     }
    // },
    data() {
        return {
            dialogVisible: false,
            loading: false,
            form: {
                platformId: null,
                categoryName: "",
                parentId: null
            },
            isAdd: true,
            parentCategory: [],
            rules: {
                platformId: [
                    {
                        required: true,
                        message: "请选择平台品类",
                        trigger: "change"
                    }
                ],
                categoryName: [
                    {
                        required: true,
                        message: "请输入品类名称",
                        trigger: "blur"
                    },
                    {
                        min: 2,
                        max: 50,
                        message: "长度在 2 到 50 个字符，且不能为空格",
                        trigger: "blur"
                    }
                ],
                parentId: [
                    {
                        required: false,
                        message: "请选择父级品类",
                        trigger: "change"
                    }
                ]
            }
        }
    },
    computed: {
        ...mapState({
            platformCategory: state => state.customer.platformCategory
        }),
        dialogTitle() {
            return `平台品类${this.isAdd ? "新增" : "编辑"}`
        }
    },
    watch: {
        dialogVisible: {
            immediate: true,
            deep: true,
            async handler(newVal) {
                if (newVal) {
                    this.getParent()
                    return
                }
                this.parentCategory = []
            }
        }
    },
    methods: {
        setObjLevel(total, target, max) {
            // 设置级联级数
            return target.map(item => {
                if (total === max) {
                    item.children = null
                } else {
                    item.level = total
                    if (item.children && item.children.length) {
                        item.level++
                        item.children = this.setObjLevel(
                            item.level,
                            item.children,
                            max
                        )
                    }
                }
                return item
            })
        },
        recursiveArray(target, id) {
            return target.map(item => {
                item.disabled = item.id === id || item.parentId === id
                if (item.children && item.children.length) {
                    item.children = this.recursiveArray(item.children, id)
                }
            })
        },
        handleDialog(query = {}, isAdd) {
            // form
            this.isAdd = !!isAdd
            query.platformId &&
                Object.keys(query).forEach(item => {
                    this.form[item] = query[item]
                })
            this.handleVisible(true)
        },
        handleVisible(boo) {
            this.dialogVisible = boo
        },
        handleCancel() {
            Object.keys(this.form).forEach(item => {
                this.form[item] = null
            })
            this.resetForm()
            this.handleVisible(false)
        },
        handleConfirm(step) {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.operationCategory(step)
                } else {
                    console.log("error submit!!")
                    return false
                }
            })
        },
        resetForm() {
            this.$refs.form.resetFields()
        },
        async operationCategory(step) {
            // 新建or编辑
            const reqMethod = this.isAdd ? "create" : "update"
            try {
                this.form.parentId = this.form.parentId || 0
                const res = await customerSvc[`${reqMethod}Category`](this.form)
                if (step !== "continue") {
                    this.handleVisible(false)
                } else {
                    this.getParent()
                    this.form.categoryName = ''
                }
                this.$message({
                    message: `保存成功${
                        step === "continue" ? ", 可以继续修改添加" : ""
                    }`,
                    type: "success"
                })
                this.$emit("handle-refresh")
            } catch (err) {
                console.log('err', err)
            }
        },
        handleClose(val) {
            const cascader = this.$refs.cascaderHandle
            cascader.dropDownVisible = false
            if (!val) {
                // 清空已选择项
                cascader.$refs.panel.checkedValue = [] //也可以是指定的值
                cascader.$refs.panel.calculateCheckedNodePaths()
            }
        },
        async getParent() {
            // 获取父类 todo:computed
            const { platformId } = this.form
            const { data } = await customerSvc.parentCategory({
                platformId
            })
            const result = this.setObjLevel(1, _.cloneDeep(data), 4) // 新增or编辑4级
            this.parentCategory = result
        },
        handlePlatformidChange() {
            // 根据品类设置父类
            this.getParent()
        }
    }
}
</script>
<style lang="less" scoped>
.add-dialog {
    /deep/.el-dialog__footer {
        padding: 10px;
        border-top: 2px solid #f0f0f0;
        .el-button {
            width: auto;
            height: auto;
            padding: 10px 18px;
            border-radius: 8px;
            font-size: 14px;
        }
    }
    /deep/.el-form-item__label {
        font-weight: 500;
        color: #606266;
    }
}
</style>
