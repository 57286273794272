<template>
    <div class="gb-category-query">
        <div class="gb-category-query-group" :class="{'group-unfold': foldState}">
            <el-select
                v-model="form.platformId"
                class="w240 mr40"
                placeholder="平台"
                :filterable="true"
                @change="handleSearch"
            >
                <el-option
                    v-for="item in platformCategory"
                    :key="item.id"
                    :label="item.platformName"
                    :value="item.id"
                ></el-option>
            </el-select>
            <el-input
                v-model.trim="form.categoryName"
                class="w240 mr40"
                placeholder="品类名称"
                clearable
                @clear="handleSearch"
                @keyup.enter.native="handleSearch"
            ></el-input>
            <!-- <el-cascader
                ref="cascaderHandle2"
                v-model="form.parentId"
                placeholder="父品类"
                class="w240 mr40"
                :options="parentCategory"
                :props="{ checkStrictly: true, value: 'id', label: 'categoryName', emitPath: false }"
                clearable
                :filterable="true"
                @change="handleClose"
            ></el-cascader> -->
            <el-input
                v-model.trim="form.parentCategoryName"
                class="w240 mr40"
                placeholder="父品类"
                clearable
                @clear="handleSearch"
                @keyup.enter.native="handleSearch"
            ></el-input>
            <!-- todo:层级 -->
            <el-select
                v-model="form.level"
                class="w240 mr40"
                placeholder="层级"
                clearable
                :filterable="true"
                @change="handleSearch"
            >
                <el-option
                    v-for="item in levels"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
            </el-select>
        </div>
        <div class="gb-category-query-btns">
            <span
                class="flod"
                :class="{'unfold': foldState}"
                @click="handleFold"
            >{{ foldText }}<i class="iconfont icon-xiala"></i></span>
            <el-button
                type="primary"
                round
                class="custom-btn"
                @click="handleSearch"
            >
                查询
            </el-button>
            <el-button round class="custom-btn" @click="handleReset">重置</el-button>
        </div>
    </div>
</template>
<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import customerSvc from 'services/customer'

export default {
    name: 'CategoryQuery',
    data () {
        return {
            foldState: false,
            form: {
                platformId: '', // 没有接口默认''
                categoryName: '',
                parentCategoryName: null,
                level: null
            },
            levels: [
                { name: '一层级', id: 0 },
                { name: '二层级', id: 1 },
                { name: '三层级', id: 2 },
                { name: '四层级', id: 3 },
                { name: '五层级', id: 4 }
            ],
            parentCategory: []
        }
    },
    computed: {
        foldText () {
            return this.foldState ? '收起' : '展开'
        },
        ...mapState({
            platformCategory: state => state.customer.platformCategory
        })
    },
    watch: {
        platformCategory: {
            immediate: true,
            deep: true,
            handler (newVal) {
                this.form.platformId = _.isEmpty(newVal) ? '' : newVal[0].id
            }
        }
        // 'form.platformId': {
        //     immediate: true,
        //     deep: true,
        //     async handler (newVal) {
        //         if (newVal) {
        //             const { data } = await customerSvc.parentCategory(this.form)
        //             this.parentCategory = data
        //             return
        //         }
        //         this.parentCategory = []
        //     }
        // }
    },
    methods: {
        handleFold () {
            this.foldState = !this.foldState
        },
        handleReset () {
            // todo:查询条件置空
            Object.keys(this.form).forEach(item => {
                this.form[item] = null
            })
            this.form.platformId = _.isEmpty(this.platformCategory) ? '' : this.platformCategory[0].id
            this.$emit('handle-search', this.form)
        },
        handleSearch () {
            // 请求接口
            this.$emit('handle-search', this.form)
        },
        handleClose (val) {
            this.handleSearch()
            const cascader = this.$refs.cascaderHandle2
            cascader.dropDownVisible = false
            if (!val) {
                // 清空已选择项
                cascader.$refs.panel.checkedValue = []// 也可以是指定的值
                cascader.$refs.panel.calculateCheckedNodePaths()
            }
        }
    }
}
</script>
<style lang="less" scoped>
.gb-category-query {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .w240 {
        width: 240px;
    }

    .w160 {
        width: 160px;
    }

    .w340 {
        width: 340px;
    }

    .mr40 {
        margin-right: 40px;
    }

    .mr20 {
        margin-right: 20px;
    }

    &-group {
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        max-height: 52px;
        overflow: hidden;
        transition: all 0.3s;

        & > div {
            margin-bottom: 12px;
        }

        /deep/.el-checkbox {
            padding-top: 10px;
        }

        .textarea-group {
            display: flex;
        }
    }

    .group-unfold {
        max-height: 100%;
    }

    &-btns {
        min-width: 194px;

        .flod {
            display: inline-flex;
            margin-right: 8px;
            font-size: 14px;
            font-weight: 400;
            color: #3595e1;
            cursor: pointer;
        }

        .flod i {
            display: flex;
            align-items: center;
            transition: all 0.3s;
            transform: scale(0.6);
        }

        .unfold i {
            transform: scale(0.6) rotate(180deg);
        }

        .custom-btn {
            padding: 12px 18px;
            font-size: 14px;
            border: 1px solid #dcdfe6;
            border-radius: 8px;

            & + btn {
                background: #3595e1;
            }
        }
    }
}
</style>
