<template>
    <div class="gb-category-list">
        <div class="gb-category-list-head">
            <div class="gb-category-list-head-operation">
                <div class="item" @click="handleAdd(true)">
                    <i class="iconfont icon-benzhouxinzengfenxiaoshang" />新增
                </div>
            </div>
        </div>
        <div class="gb-category-list-main">
            <el-table
                :data="listData"
                style="width: 100%;"
                height="500"
                row-key="id"
                :tree-props="{ children: 'children' }"
            >
                <!-- <el-table-column
                    align="left"
                    label="序号"
                    width="100"
                    prop="index"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.$index + 1 }}</span>
                    </template>
                </el-table-column> -->
                <el-table-column align="left" label="编码" prop="categoryNo">
                </el-table-column>
                <el-table-column
                    align="center"
                    label="平台品类"
                    prop="categoryName"
                >
                </el-table-column>
                <el-table-column
                    align="center"
                    label="父品类"
                    prop="parentCategoryName"
                >
                </el-table-column>
                <el-table-column align="center" label="操作" prop="desc">
                    <template slot-scope="scope">
                        <!-- todo:form -->
                        <el-button
                            type="text"
                            @click="handleAdd(false, scope.row)"
                        >
                            编辑
                        </el-button>
                        <!-- todo:v-if 启用or停用 -->
                        <el-button
                            v-if="!scope.row.enabled"
                            type="text"
                            @click="handleItem('enabled', {id: scope.row.id, version: scope.row.version})"
                        >
                            启用
                        </el-button>
                        <el-button
                            v-else
                            type="text"
                            @click="handleItem('disabled', {id: scope.row.id, version: scope.row.version})"
                        >
                            停用
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <add-dialog ref="addDialog" @handle-refresh="handleRefresh"></add-dialog>
    </div>
</template>
<script>
import customerSvc from 'services/customer'
import addDialog from './addDialog'

export default {
    name: 'CategoryList',
    components: {
        addDialog
    },
    props: {
        platformId: {
            type: [String, Number],
            default: ''
        },
        listData: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {}
    },
    methods: {
        handleAdd (
            add,
            query = {
                platformId: null,
                categoryName: '',
                parentId: null,
                id: null,
                version: null
            }
        ) {
            const {
                platformId = null,
                categoryName = '',
                parentId = null,
                id = null,
                version = null
            } = query
            const form = {
                platformId,
                categoryName,
                parentId
            }
            if (add) {
                form.platformId = this.platformId
            } else {
                form.id = id
                form.version = version
            }
            this.$refs.addDialog.handleDialog(form, add)
        },
        handleImport () {},
        handleDisabled () {},
        handleClickTab () {},
        async handleItem (type, query) {
            // 停用 or 启用 是否要确认框
            try {
                const res = await customerSvc[`${type}Category`](query)
                this.handleRefresh()
                // todo list
            } catch (err) {
                console.log(err)
            }
        },
        handleRefresh () {
            this.$emit('handle-refresh')
        }
    }
}
</script>
<style lang="less" scoped>
.gb-category-list {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-top: 13px;
    overflow: hidden;
    &-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        & + div {
            margin-top: 19px;
        }
        &-operation {
            display: flex;
            align-items: center;
        }
        &-operation .item {
            margin-right: 19px;
            font-weight: 400;
            color: #3595e1;
            cursor: pointer;
        }
        &-operation .item i {
            font-size: 12px;
            margin-right: 9px;
        }
        & > span {
            color: #606266;
        }
    }
    &-main {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }
    /deep/.el-table {
        &::before {
            height: 0;
        }
        .has-gutter th {
            background-color: #f7f9fc;
            font-size: 14px;
            font-weight: 500;
            color: #606266;
        }
        .expanded + tr:not(.el-table__row) > td {
            padding: 0;
            background: #f7f9fc;
            &:hover {
                background: #f7f9fc !important;
            }
            .el-pagination * {
                background: transparent;
            }
        }
        .el-button--text {
            padding: 0;
        }
    }
}
</style>
